// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-template-js": () => import("/opt/build/repo/src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-contact-js": () => import("/opt/build/repo/src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("/opt/build/repo/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-news-js": () => import("/opt/build/repo/src/pages/en/news.js" /* webpackChunkName: "component---src-pages-en-news-js" */),
  "component---src-pages-en-projects-js": () => import("/opt/build/repo/src/pages/en/projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-en-publications-js": () => import("/opt/build/repo/src/pages/en/publications.js" /* webpackChunkName: "component---src-pages-en-publications-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/build/repo/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-projekty-js": () => import("/opt/build/repo/src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-publikacje-js": () => import("/opt/build/repo/src/pages/publikacje.js" /* webpackChunkName: "component---src-pages-publikacje-js" */),
  "component---src-pages-wydarzenia-js": () => import("/opt/build/repo/src/pages/wydarzenia.js" /* webpackChunkName: "component---src-pages-wydarzenia-js" */)
}

